<mat-form-field class="flex flex-1">
  <mat-label>Column</mat-label>
  <mat-select [value]="filterItem.column" name="column" required (selectionChange)="columnChanged($event)">
    @for (column of columns(); track column) {
      <mat-option [value]="column.columnName">{{column.label}}</mat-option>
    }
  </mat-select>
</mat-form-field>
<mat-form-field class="flex flex-1">
  <mat-label>Filter Type</mat-label>
  <mat-select [(ngModel)]="filterItem.filterType" name="filterType" required>
    <mat-option [value]="filterTypes.Contains">Contains</mat-option>
    <mat-option [value]="filterTypes.DoesNotContain">Does Not Contain</mat-option>
  </mat-select>
</mat-form-field>
<sn-carrier-search [multi]="true" (carriersSelected)="carrierSelected($event);" label="Value"
  class="flex flex-100 translateYN10"
  [carrierIds]="getPreselectedCarrierIds()"
  [searchDAT]="false"
></sn-carrier-search>