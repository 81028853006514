
<mat-form-field class="flex flex-1">
  <mat-label>Column</mat-label>
  <mat-select [value]="filterItem.column" required (selectionChange)="columnChanged($event)">
    @for (column of columns(); track column) {
      <mat-option [value]="column.columnName">{{column.label}}</mat-option>
    }
  </mat-select>
</mat-form-field>
<mat-form-field class="flex flex-1">
  <mat-label>Filter Type</mat-label>
  <mat-select [(ngModel)]="filterItem.filterType" name="filterType-{{filterItem.id}}" name="filterType-{{filterItem.id}}" required>
    <mat-option [value]="filterTypes.Equals">Equals</mat-option>
    <mat-option [value]="filterTypes.DoesNotEqual">Does Not Equal</mat-option>
    <mat-option [value]="filterTypes.Between">Between</mat-option>
    <mat-option [value]="filterTypes.GreaterThan">Greater Than</mat-option>
    <mat-option [value]="filterTypes.GreaterThanOrEqual">Greater Than Or Equal</mat-option>
    <mat-option [value]="filterTypes.LessThan">Less Than</mat-option>
    <mat-option [value]="filterTypes.LessThanOrEqual">Less Than Or Equal</mat-option>
    <mat-option [value]="filterTypes.IsEmpty">Is Empty</mat-option>
    <mat-option [value]="filterTypes.IsNotEmpty">Is Not Empty</mat-option>
  </mat-select>
</mat-form-field>
@if (filterItem.filterType != filterTypes.IsEmpty && filterItem.filterType != filterTypes.IsNotEmpty) {
  <sn-date-filter-selector class="flex flex-1" [hasSpecific]="hasSpecific()" [hasRange]="hasRange()" [hasMeta]="hasMeta()" [dateFilter]="dateFilter" [filterType]="filterItem.filterType" (dateChanged)="dateChanged($event)"></sn-date-filter-selector>
}