import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { GridFilterBaseComponent } from '../grid-filter-base-component';
import { ControlContainer, NgForm, FormsModule } from '@angular/forms';
import { DateFilterDto, DateFilterSelectorComponent } from '../../date-filter-selector/date-filter-selector.component';
import { DateTime } from 'luxon';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'sn-date-grid-filter',
    templateUrl: './date-grid-filter.component.html',
    styleUrls: ['./date-grid-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    imports: [MatFormFieldModule, MatSelectModule, MatOptionModule, FormsModule, DateFilterSelectorComponent]
})
export class DateGridFilterComponent extends GridFilterBaseComponent implements OnInit {
  dateFilter: DateFilterDto = new DateFilterDto();
  
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.dateFilter.startDate = this.filterItem.value1 ? DateTime.fromISO(this.filterItem.value1) : null;
    this.dateFilter.endDate = this.filterItem.value2 ? DateTime.fromISO(this.filterItem.value2) : null;
    if(this.filterItem.meta) {
      this.dateFilter.meta = this.filterItem.meta;
    }
  }

  openMetaMenu(event: PointerEvent, menu: MatMenuTrigger) {
    event.stopPropagation();
    menu.openMenu();
  }

  dateChanged(event: DateFilterDto) {
    this.filterItem.value1 = event.startDate ? event.startDate.toFormat('yyyy-MM-dd') : undefined;
    this.filterItem.value2 = event.endDate ? event.endDate.toFormat('yyyy-MM-dd') : undefined;
    this.filterItem.meta = event.meta;
  }

  hasSpecific() {
    return this.filterItem.filterType != this.filterTypes.Between;
  }

  hasRange() {
    return this.filterItem.filterType == this.filterTypes.Between;
  }

  hasMeta() {
    return this.filterItem.filterType == this.filterTypes.Equals;
  }
}