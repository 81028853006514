
<mat-form-field class="flex flex-1">
  <mat-label>Column</mat-label>
  <mat-select [value]="filterItem.column" required (selectionChange)="columnChanged($event)">
    @for (column of columns(); track column) {
      <mat-option [value]="column.columnName">{{column.label}}</mat-option>
    }
  </mat-select>
</mat-form-field>
<mat-form-field class="flex flex-1">
  <mat-label>Filter Type</mat-label>
  <mat-select [(ngModel)]="filterItem.filterType" name="filterType-{{filterItem.id}}" required>
    <mat-option [value]="filterTypes.Equals">Equals</mat-option>
    <mat-option [value]="filterTypes.DoesNotEqual">Does Not Equal</mat-option>
    <mat-option [value]="filterTypes.Contains">Contains</mat-option>
    <mat-option [value]="filterTypes.IsEmpty">Is Empty</mat-option>
    <mat-option [value]="filterTypes.IsNotEmpty">Is Not Empty</mat-option>
  </mat-select>
</mat-form-field>
@if (filterItem.filterType != filterTypes.IsEmpty && filterItem.filterType != filterTypes.IsNotEmpty) {
  <mat-form-field class="flex flex-1">
    <mat-label>Value</mat-label>
    <input matInput [(ngModel)]="filterItem.value1" name="value1-{{filterItem.id}}" required />
  </mat-form-field>
}
