import { Component, OnInit, output } from '@angular/core';

@Component({
    selector: 'sn-material-grid-empty-columns',
    templateUrl: './material-grid-empty-columns.component.html',
    styleUrls: ['./material-grid-empty-columns.component.scss'],
    standalone: true
})
export class MaterialGridEmptyColumnsComponent implements OnInit {

  readonly filter = output<any>();

  constructor() { }

  ngOnInit(): void {
  }
  
  onFilter() {
    this.filter.emit(null);
  }
}
