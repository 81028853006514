import { Component, ChangeDetectionStrategy, input } from '@angular/core';
import { SelectOptionDto } from '../../../../core/services/api.service';
import { GridFilterBaseComponent } from '../grid-filter-base-component';
import { ControlContainer, NgForm, FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'sn-select-grid-filter',
    templateUrl: './select-grid-filter.component.html',
    styleUrls: ['./select-grid-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
    imports: [MatFormFieldModule, MatSelectModule, MatOptionModule, FormsModule]
})
export class SelectGridFilterComponent extends GridFilterBaseComponent {
  constructor() {
    super();
  }

  readonly isFlags = input<boolean>(false);

  options: SelectOptionDto[] = [];
  selectedOptions: SelectOptionDto[] = [];

  ngOnInit(): void {
    if(this.filterItem.filterType == this.filterTypes.Equals) {
      this.filterItem.filterType = this.filterTypes.Contains;
    }

    this.options = this.columns().find(f => f.columnName == this.filterItem.column)?.selectOptions ?? [];

    if(this.filterItem.value1) {
      if(this.isFlags()) {
        // get selected values from value1 (int value)
        this.setSelectedFlags(BigInt(this.filterItem.value1));
      } else {
        let values = this.filterItem.value1.split(",");

        values.forEach(x => {
          let item = this.options.find(y => y.value == x);

          if(item) {
            this.selectedOptions.push(item);
          }
        });
      }
    }
  }

  checkDisabled(select: SelectOptionDto) {
    return select.value != 'All' && this.selectedOptions.some(s => s.value == 'All')
  }

  private setSelectedFlags(flagValue: bigint) {
    this.options.forEach(x => {
      let optionValue = BigInt(x.value);

      if ((optionValue & flagValue) != BigInt(0)) {
        this.selectedOptions.push(x);
      }
    });
  }

  selectChanged() {
    if(this.isFlags()) {
      let val = BigInt(0);
      this.selectedOptions.forEach(x => {
        let valueNum = BigInt(x.value);
        val = val | valueNum;
      });

      this.setSelectedFlags(val);
      this.filterItem.value1 = val.toString();
    } else {
      this.filterItem.value1 = this.selectedOptions.map(x => x.value).join(",")
    }
  }

  selectCompare(option1: any, option2: any) {
    return option1 == option2;
  }
}
