<mat-form-field class="flex flex-1">
  <mat-label>Column</mat-label>
  <mat-select [value]="filterItem.column" name="column" required (selectionChange)="columnChanged($event)">
    @for (column of columns(); track column) {
      <mat-option [value]="column.columnName">{{column.label}}</mat-option>
    }
  </mat-select>
</mat-form-field>
<mat-form-field class="flex flex-1">
  <mat-label>Filter Type</mat-label>
  <mat-select [(ngModel)]="filterItem.filterType" [name]="'filterType' + id()" required>
    <mat-option [value]="filterTypes.Contains">Contains</mat-option>
    <mat-option [value]="filterTypes.DoesNotContain">Does Not Contains</mat-option>
    <mat-option [value]="filterTypes.IsEmpty">Is Empty</mat-option>
    <mat-option [value]="filterTypes.IsNotEmpty">Is Not Empty</mat-option>
  </mat-select>
</mat-form-field>
@if (filterItem.filterType == filterTypes.Contains || filterItem.filterType == filterTypes.DoesNotContain) {
  <mat-form-field class="flex flex-1">
    <mat-label>Value</mat-label>
    <mat-select multiple required [(ngModel)]="selectedOptions" (ngModelChange)="selectChanged()" [name]="'selectedOptions' + id()" [compareWith]="selectCompare">
      @for (select of options; track select) {
        <mat-option [disabled]="checkDisabled(select)" [value]="select">{{select.label}}</mat-option>
      }
    </mat-select>
  </mat-form-field>
}