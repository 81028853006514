import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Renderer2, inject, input, output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { FilterDto, FiltersClient, GridColumnDto, ListTypeEnum } from '../../core/services/api.service';
import { PermissionCheckerService } from '../../core/services/permission-checker.service';
import { RequestService } from '../../core/services/request.service';
import { ApiGridStateModel } from '../../models/grid-state.model';
import { Permissions } from '../common/permissions';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { GridFilterModalComponent } from '../modals/grid-filter-modal/grid-filter-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NotificationAddEditModalComponent } from '../../user-profile/notification-add-edit-modal/notification-add-edit-modal.component';


@Component({
    selector: 'sn-grid-filter-slide-out',
    templateUrl: './grid-filter-slide-out.component.html',
    styleUrls: ['./grid-filter-slide-out.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIconModule, MatTooltipModule, MatMenuModule, CdkDropList, CdkDrag, MatCheckboxModule, CdkDragHandle]
})
export class GridFilterSlideOutComponent implements OnInit {
  private _filterClient = inject(FiltersClient);
  private _matDialog = inject(MatDialog);
  private _requestService = inject(RequestService);
  private _cdr = inject(ChangeDetectorRef);
  private _permissionService = inject(PermissionCheckerService);
  private _renderer = inject(Renderer2);

  // inputs
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  @Input() filters: FilterDto[] = [];
  readonly columns = input<GridColumnDto[]>([]);
  readonly state = input<ApiGridStateModel | undefined>(undefined);
  readonly listType = input<string | undefined>(undefined);

  // outputs
  readonly filterSelected = output<FilterDto>();
  readonly filterDeleted = output<FilterDto>();
  readonly columnOrdered = output<string[]>();
  readonly columnToggled = output<GridColumnDto>();
  readonly refreshFilters = output();
  readonly resetSelectedColumns = output();

  // locals
  isDeveloper: boolean = false;
  show: boolean = false;
  codeOpen: boolean = false;
  tabIndex: number = 0;
  // set to false after opening once, as the filter button gets re-rendered and animated upon close if not set
  showBounceAnimation: boolean = true;
  permissions = Permissions;

  constructor() {
    const _renderer = this._renderer;

    _renderer.listen('window', 'click', (e: Event) => {
      let target = <any>e.target;

      if(target.id == "filter-icon-wrapper" || target.id == "filter-icon") {
        return;
      }

      if(this.show && !this.codeOpen) {
        if(!target.closest("sn-grid-filter-slide-out") && !target.closest("#menuFilterButton")) {
          this.close();
        }
      }

      this.codeOpen = false;
    });
  }

  ngOnInit(): void {
    this._permissionService.isGranted(Permissions.DEV_ALL).then(x => {
      this.isDeveloper = x;
      this._cdr.detectChanges();
    });
  }

  open(codeOpen: boolean = false) {
    this.codeOpen = codeOpen;
    this.showBounceAnimation = false;
    this.show = true;
    this._cdr.detectChanges();
  }

  close() {
    this.show = false;
    this._cdr.detectChanges();
  }

  toggleFilter(filter: FilterDto) {
    this.close();

    if(this.isFilterSelected(filter.id)) {
      this.filterDeleted.emit(filter);
    } else {
      this.filterSelected.emit(filter);
    }
  }

  toggleFilterMenu($event: any, trigger: MatMenuTrigger) {
    $event.stopPropagation();
    trigger.openMenu();
  }

  dropColumn(event: CdkDragDrop<string[]>) {
    const columns = this.columns();
    moveItemInArray(columns, event.previousIndex, event.currentIndex);

    // reset order of all columns
    let cols = columns.filter(x => x.selected).map(x => x.columnName ?? "").filter(f => f);
    this.columnOrdered.emit(cols);
  }

  toggleFavorite($event: any, filter: FilterDto) {
    if(filter.isSystem)
      return;

    $event.stopPropagation();
    filter.isFavorite = !filter.isFavorite;
    this._filterClient.filters_Put(filter.id, filter).subscribe();
  }

  openFilterModal(filter?: FilterDto) {
    this._matDialog.open(GridFilterModalComponent, {
      data: {
        columns: this.columns().sort((a,b) => {
          if(a.label && b.label) {
            return a.label.localeCompare(b.label)
          }
          return 0;
        }),
        filter: filter,
        listType: this.listType()
      },
      panelClass: 'grid-filter-panel'
    }).afterClosed().subscribe(x => {
      if(x) {
        this.refreshFilters.emit();
        this.filterSelected.emit(x || filter);
      }
    });
  }

  editFilter(filter: FilterDto) {
    let editFilter = <FilterDto>{ ...filter }; // clone
    editFilter.filterItems = [...filter.filterItems];

    this.openFilterModal(editFilter);
  }

  deleteFilter(filter: FilterDto) {
    const confirmDialogRef = this._matDialog.open(ConfirmDialogComponent, {
      width: '300px',
      data: { title: "Delete Filter", message: "Delete Filter '" + filter.name + "'?", cancelButtonText: "Cancel", okButtonText: "Delete" },
      disableClose: true
    });

    confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._requestService.delete(this._filterClient.filters_Delete(filter.id), x => this.refreshFilters.emit(), "Filter");
        this.filterDeleted.emit(filter);
      }
    });
  }

  isFilterSelected(filterId: number) {
    const state = this.state();
    if(state?.selectedFilterIds) {
      return state?.selectedFilterIds?.indexOf(filterId) > -1;
    }
    return false;
  }

  onResetSelectedColumns() {
    this.resetSelectedColumns.emit();
  }

  scheduleNotification(filter: FilterDto) {
    this._matDialog.open(NotificationAddEditModalComponent, {
      width: '500px',
      data: {
        filterData: {
          filter: filter,
          columns: this.columns(),
        }
      }
    });
  } 
}
